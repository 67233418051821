import './SettingsCustomisationContent.less'

import { Button, Form, Input } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { DatacColorPicker, DatacFormItem, DatacImageUpload, DatacMessage, DatacTitle } from '../../../components/common'
import { useScopedIntl } from '../../../hooks'
import {
  AclAction,
  AclFeature,
  CustomisationData,
  FileInfo,
  FileState,
  ImageUploadType,
  fetchCustomisationData as fetchCustomisationDataRequest,
  fetchFileUrls,
  updateCustomisationData
} from '../../../requests'
import { validateColor, validateEmail, validateRequired } from '../../../validation'
import { UserContext } from '../../auth'
import { SettingsLayout } from '../SettingsLayout'

interface FormData {
  logoConfig: FileInfo[]
  mainColor: string
  buttonColor: string
  replyToEmail: string
}

export const SettingsCustomisationContent: React.FC = () => {
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [form] = Form.useForm()
  const intl = useScopedIntl('')
  const intlSettings = useScopedIntl('settings.customisation')
  const { user } = useContext(UserContext)
  const [formKey, setFormKey] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [customisationData, setCustomisationData] = useState<CustomisationData>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [imageError, setImageError] = useState<string>(null)

  useEffect(() => {
    fetchCustomisationData()
  }, [])

  const fetchCustomisationData = () => {
    if (!user.canDo(AclFeature.Customisation)(AclAction.Edit)) return

    fetchCustomisationDataRequest(false, {
      onSuccess: (data: CustomisationData) => {
        setCustomisationData(data)
        setIsLoading(false)
        setIsEditingOn(false)
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsLoading(false)
      }
    })
  }

  const resetImageList = () => {
    if (!customisationData.logoId) return

    form.setFieldsValue({ logoConfig: [{ id: customisationData.logoId, state: FileState.Preloading }] })
    fetchFileUrls(
      {
        fileIds: [customisationData.logoId]
      },
      {
        onSuccess: fileUrls => {
          form.setFieldsValue({
            logoConfig: [
              { id: customisationData.logoId, state: FileState.Ready, url: fileUrls[customisationData.logoId] }
            ]
          })
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onImageListChange = (_: FileInfo[], errors: string[]) => {
    setImageError(errors.length ? errors.join(', ') : undefined)
  }

  useEffect(() => {
    if (customisationData) resetFields()
  }, [customisationData])

  const resetFields = () => {
    form.setFieldsValue({
      mainColor: customisationData.mainColor,
      buttonColor: customisationData.buttonColor,
      replyToEmail: customisationData.replyToEmail,
      logoConfig: []
    })
    resetImageList()
    setFormKey(formKey === 0 ? 1 : 0)
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSubmit = (data: FormData) => {
    setIsSaving(true)
    const newCustomisationData = {
      ...data,
      logoId: data.logoConfig[0]?.id || null
    }
    updateCustomisationData(newCustomisationData, {
      onSuccess: () => {
        user.saveRecentColors([newCustomisationData.mainColor])
        user.saveRecentColors([newCustomisationData.buttonColor])
        setCustomisationData(newCustomisationData)
        setIsEditingOn(false)
        setIsSaving(false)
      },
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  return (
    <SettingsLayout>
      <div className="settings-customisation-content">
        <DatacTitle type="h2" className="personal-details-content__header">
          {intlSettings('title')}
        </DatacTitle>
        <div className="settings-customisation-content__body">
          <div className="settings-customisation-content__form">
            <Form form={form} onFinish={data => onSubmit(data as FormData)} key={formKey}>
              <div className="settings-customisation-content__row">
                <div className="settings-customisation-content__label">{intlSettings('logo.label')}</div>
                <div className="settings-customisation-content__control">
                  <DatacFormItem name="logoConfig" label=" " error={imageError ?? undefined}>
                    <DatacImageUpload
                      onChange={onImageListChange}
                      disabled={!isEditingOn}
                      uploadType={ImageUploadType.Logo}
                    />
                  </DatacFormItem>
                </div>
              </div>

              <div className="settings-customisation-content__row">
                <div className="settings-customisation-content__label">{intlSettings('main_color.label')}</div>
                <div className="settings-customisation-content__control">
                  <DatacFormItem
                    name="mainColor"
                    label=" "
                    validate={[
                      validateRequired(intlSettings('main_color.validation.required')),
                      validateColor(intlSettings('main_color.validation.wrong'))
                    ]}
                    getValueFromEvent={color => {
                      return `#${color.toHex()}`
                    }}
                  >
                    <DatacColorPicker showText disabled={!isEditingOn} size="large" previewLayout="beside" />
                  </DatacFormItem>
                </div>
              </div>

              <div className="settings-customisation-content__row">
                <div className="settings-customisation-content__label">{intlSettings('button_color.label')}</div>
                <div className="settings-customisation-content__control">
                  <DatacFormItem
                    name="buttonColor"
                    label=" "
                    validate={[
                      validateRequired(intlSettings('button_color.validation.required')),
                      validateColor(intlSettings('button_color.validation.wrong'))
                    ]}
                    getValueFromEvent={color => {
                      return `#${color.toHex()}`
                    }}
                  >
                    <DatacColorPicker showText disabled={!isEditingOn} size="large" previewLayout="beside" />
                  </DatacFormItem>
                </div>
              </div>

              <div className="settings-customisation-content__row">
                <div className="settings-customisation-content__label">{intlSettings('reply_to_email.label')}</div>
                <div className="settings-customisation-content__control">
                  <DatacFormItem
                    name="replyToEmail"
                    label=" "
                    validate={validateEmail(intlSettings('email.validation.wrong'))}
                  >
                    <Input
                      disabled={!isEditingOn}
                      size="large"
                      placeholder={intlSettings('reply_to_email.placeholder')}
                    />
                  </DatacFormItem>
                </div>
              </div>
            </Form>
          </div>
          <div className="settings-customisation-content__form-controls">
            {isEditingOn ? (
              <>
                <Button size="large" type="default" onClick={onCancel}>
                  {intlSettings('cancel')}
                </Button>
                <Button size="large" type="primary" loading={isSaving} onClick={() => form.submit()}>
                  {intlSettings('save')}
                </Button>
              </>
            ) : (
              <Button size="large" type="primary" onClick={() => setIsEditingOn(true)} loading={isLoading}>
                {intlSettings('edit')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}
