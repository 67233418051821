import React from 'react'

import { BasicLayout } from '../../../components/BasicLayout'
import { AuthRoute } from '../../../components/auth/AuthRoute'
import { SettingsCustomisationContent } from '../../../components/settings/SettingsCustomisationContent'

const IndexPage = () => {
  return (
    <AuthRoute>
      <BasicLayout>
        <SettingsCustomisationContent />
      </BasicLayout>
    </AuthRoute>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Customization</title>
